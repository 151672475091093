



import { VeeValidateConfig } from 'vee-validate/dist/types/config';
import { MailaddressReminder } from '../../../../../types/typescript-axios/api';
import { Component, Vue, Emit } from 'vue-property-decorator';
import VeeValidate from '../../../../plugins/vee-validate';
import store from '../../../../store';
import StepNavi from '../../../../components/contents/stepNavi/StepNavi.vue';
import axios, {AxiosResponse, AxiosError} from 'axios';
import { ErrorResponse } from '../../../../../types/typescript-axios/api';
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css';
import { getSuggestionList } from '../../../../plugins/helper';
import { load } from 'recaptcha-v3';
import auth from '../../../../store/auth';
import { next } from 'dom7';

Component.registerHooks([
  'beforeRouteEnter',
]);

@Component({
  components: {
    StepNavi,
    VueSimpleSuggest,
  },
  mixins: [ VeeValidate ],
})
export default class AccountEditAccountIndex extends Vue {
  private errorMessage: boolean = false;

  private companyNames: string[] = [];

  get ruleMailaddress() {
    return {
      required: true,
      email: true,
      max: 50,
    };
  }

  get fromPath(): string|null {
    return sessionStorage.getItem('fromPath');
  }

  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  private get status(): string {
    const $parent: any = this.$parent;
    return $parent.status;
  }

  private get name1(): string {
    const $parent: any = this.$parent;
    return $parent.name1;
  }

  private get name2(): string {
    const $parent: any = this.$parent;
    return $parent.name2;
  }

  private get beforeMail(): string {
    const $parent: any = this.$parent;
    return $parent.beforeMail;
  }

  private get mail(): string {
    const $parent: any = this.$parent;
    return $parent.mail;
  }

  private set mail(value: string) {
    this.inputNewMail(value);
  }

  @Emit()
  private inputNewMail(value: string) {
    const $parent: any = this.$parent;
    $parent.setMail(value);
  }

  private get mailReInput(): string {
    const $parent: any = this.$parent;
    return $parent.mailReInput;
  }

  private set mailReInput(value: string) {
    this.inputMailReInput(value);
  }

  @Emit()
  private inputMailReInput(value: string) {
    const $parent: any = this.$parent;
    $parent.setMailReInput(value);
  }

  private get password(): string {
    const $parent: any = this.$parent;
    return $parent.password;
  }

  private set password(value: string) {
    this.inputPassword(value);
  }


  @Emit()
  private inputPassword(value: string) {
    const $parent: any = this.$parent;
    $parent.setPassword(value);
  }

  @Emit()
  private async onSubmit() {
    const $observer: any = this.$refs.observer;
    const errorDome: any = this.$refs.errorMessage;
    $observer.reset();
    $observer.validate().then(async (result: boolean) => {
      if (!result) {
        this.errorMessage = true;
        Vue.nextTick()
        .then(() => {
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
        return;
      }

      const paramsData: any = {
        lastname: this.name1,
        firstname: this.name2,
        password: this.password,
        email: this.mail.replace(/\r?\n/g, ''),
        email_old: this.beforeMail.replace(/\r?\n/g, ''),
      };
      const $paramMail = paramsData;
      const recaptcha2 = await load(process.env.VUE_APP_RECAPTCHA_KEY);
      const token2 = await recaptcha2.execute('homepage');
      $paramMail.recaptchaToken = token2;
      $paramMail.token = auth.state.token;
      const nonce = await store.dispatch('nonce/getData', null , {root: true});
      const query = new URLSearchParams(nonce);
      const resMail: boolean = await axios.post<MailaddressReminder, AxiosResponse>(
        '/wp-json/moc/account/mailaddress/mail?' + query.toString(),
        $paramMail,
      )
      .then((response: any) => {
        if (response.data.success) {
          return true;
        } else {
          return false;
        }
        return response;
      }, (error: any) => {
        return false;
      });
      if (!resMail) {
        this.errorMessage = true;
        Vue.nextTick()
        .then(() => {
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
        return;
      }
      this.$router.replace('./send/');
    });
  }

  @Emit()
  private backpage(): void {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
  }

  @Emit()
  private suggestOff() {
    const ref: any = this.$refs;
    ref.suggest.hideList();
  }

  @Emit()
  private suggestOn() {
    const ref: any = this.$refs;
    ref.suggest.showList();
  }

  @Emit()
  private companyBlur() {
    window.setTimeout(() => this.suggestOff(), 300);
  }

  @Emit()
  private async suggestionList() {
    return await axios.get('/wp-json/moc/company')
    .then((res) => this.companyNames = res.data )
    .catch( (e: AxiosError<ErrorResponse>) => {
      console.log(e);
    });
  }
}
